import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  HOME,
  JOURNALS,
  OUR_STORY,
  PROGRAMS,
  APPLY,
  NOT_FOUND,
  PC,
  SE,
  GROWTH,
  OPERATIONS,
} from '../utils/RoutesList';

const Home = React.lazy(() => import('../pages/home/HomePage'));
const OurStory = React.lazy(() => import('../pages/our-story/OurStoryPage'));
const Programs = React.lazy(() => import('../pages/programs/ProgramsPage'));
const Journals = React.lazy(() => import('../pages/journal/JournalPage'));
const Apply = React.lazy(() => import('../pages/apply/ApplyPage'));
const ApplySE = React.lazy(() => import('../pages/apply/ApplySE'));
const ApplyPC = React.lazy(() => import('../pages/apply/ApplyPC'));
const ApplyGrowth = React.lazy(() => import('../pages/apply/ApplyGrowth'));
const ApplyOperations = React.lazy(() =>
  import('../pages/apply/ApplyOperations')
);

const NotFound = React.lazy(() => import('../pages/NotFound'));

export default function RoutesComponent() {
  return (
    <Box component='section' flexGrow='1'>
      <Routes>
        <Route index path={HOME} element={<Home />} />
        <Route path={OUR_STORY} element={<OurStory />} />
        <Route path={PROGRAMS} element={<Programs />} />
        <Route path={JOURNALS} element={<Journals />} />
        <Route path={APPLY} element={<Apply />} />
        <Route path={SE} element={<ApplySE />} />
        <Route path={PC} element={<ApplyPC />} />
        <Route path={GROWTH} element={<ApplyGrowth />} />
        <Route path={OPERATIONS} element={<ApplyOperations />} />
        <Route path={NOT_FOUND} element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Box>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.my-form > .ButtonWrapper-sc-__sc-1qu8p4z-0 {
  display: "none";
}
.loxspH {
  display: none;
}
a[href^="https://www.typeform.com/explore/?utm_campaign=KGrZUeYG&utm_source=typeform.com-18448718-basic&utm_medium=typeform&utm_content=typeform-footer&utm_term=EN"]
{
  display: none;
}
a[href^="https://www.typeform.com"]
{
  display: none;
}
.gJxRUj {
  display: none;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;gCAE8B;EAC9B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;;EAEE,aAAa;AACf;AACA;;EAEE,aAAa;AACf;AACA;EACE,aAAa;AACf","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Poppins\", -apple-system, BlinkMacSystemFont, \"Segoe UI\",\n    \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n    \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n.my-form > .ButtonWrapper-sc-__sc-1qu8p4z-0 {\n  display: \"none\";\n}\n.loxspH {\n  display: none;\n}\na[href^=\"https://www.typeform.com/explore/?utm_campaign=KGrZUeYG&utm_source=typeform.com-18448718-basic&utm_medium=typeform&utm_content=typeform-footer&utm_term=EN\"]\n{\n  display: none;\n}\na[href^=\"https://www.typeform.com\"]\n{\n  display: none;\n}\n.gJxRUj {\n  display: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Box
      sx={{ height: "100vh", position: "relative", bgcolor: "secondary.main" }}
    >
      <Box
        sx={{
          top: "50%",
          left: "50%",
          margin: 0,
          position: "absolute",
          transform: "translateY(-50%)",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Loading;

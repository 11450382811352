export const HOME = '/';
export const OUR_STORY = '/our-story';
export const PROGRAMS = '/programs';
export const JOURNALS = '/journals';
export const APPLY = '/apply';
export const NOT_FOUND = '/not-found';
export const SE = '/apply/software-engineering';
export const PC = '/apply/people-and-culture';
export const GROWTH = '/apply/growth';
export const OPERATIONS = '/apply/operations';

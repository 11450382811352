import { CssBaseline, ThemeProvider } from "@mui/material";
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import Loading from "../components/Loading";
import { theme } from "../utils/Theme";
import RoutesComponent from "./Routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <RoutesComponent />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
    
  );
}

export default App;

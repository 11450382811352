import { createTheme, responsiveFontSizes } from "@mui/material";

const primaryMainColor = "#EE7F82";
const primaryDarkColor = "#241D28";
const secondayMainColor = "#edeaea";
const secondaryGreyColor = "#b7b5b8cc";

export let theme = createTheme({
  palette: {
    primary: {
      main: primaryMainColor,
      dark: primaryDarkColor,
    },
    secondary: {
      main: secondayMainColor,
      grey: secondaryGreyColor,
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 360,
      sm: 576,
      md: 768,
      lg: 957,
      xl: 1024,
      xxl: 1200,
    },
  },
});

theme = responsiveFontSizes(theme);
